<template>
  <lazy-theme5-base-not-found
    class="tw-flex tw-items-center tw-justify-center tw-text-center"
    :is-full-screen-error="true"
    :background-color="vars.backgroundColor"
  >
    <template #title>
      <div
        class="tw-grid tw-grid-cols-12 tw-items-center tw-justify-center"
        v-if="variant === variants.v1"
      >
        <div
          class="tw-col-span-12 lg:tw-col-span-6"
          v-html="vars.statusHtml"
        ></div>
        <div class="tw-col-span-12 tw-text-left lg:tw-col-span-6">
          <h2>{{ vars.titleText }}</h2>
          <p class="tw-pb-8 tw-pt-4">{{ vars.descriptionText }}</p>
          <nuxt-link
            :to="vars.buttonLinkUrl"
            :class="`n-${vars.buttonTypeText}`"
            >{{ vars.buttonLabelText }}</nuxt-link
          >
        </div>
      </div>
      <div v-else-if="variant === variants.v2">
        <h2>{{ vars.titleText }}</h2>
        <nuxt-link
          :to="vars.buttonLinkUrl"
          :class="`n-${vars.buttonTypeText}`"
          >{{ vars.buttonLabelText }}</nuxt-link
        >
      </div>
    </template>
  </lazy-theme5-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
});
</script>
<style scoped>
::v-deep(h1) {
  font-size: 180px !important;
  padding-top: 16px;
}
</style>
