<template>
  <div class="tw-space-y-2">
    <details
      v-for="(item, index) in items"
      :key="index"
      :open="index === selectedIndex"
    >
      <summary
        class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-duration-300"
        @click.prevent="selectedIndex = index"
      >
        <h4 class="tw-flex-1">
          {{ item.question }}
        </h4>
        <svg
          width="36"
          height="36"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-duration-300"
        >
          <path
            d="M34 20L24 30L14 20"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </summary>
      <p class="tw-mt-2 tw-line-clamp-4 tw-min-h-24">
        {{ item.answer }}
      </p>
    </details>
  </div>
</template>
<script lang="ts">
export default defineNuxtComponent({
  name: 'Theme5AccordionList',
  data() {
    return {
      selectedIndex: 0,
    };
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped>
details[open] > summary svg path,
details > summary:hover svg path {
  transition: all 0.3s;
  stroke: var(--c-title_primary);
}

details[open] > summary svg {
  transform: rotate(180deg);
}
details[open] > summary h4,
details > summary:hover h4 {
  transition: all 0.3s;
  color: var(--c-title_primary);
}

details {
  overflow: hidden;
}
details > summary h4 {
  color: var(--c-title_tertiary);
}
details p {
  color: var(--c-text-tertiary);
}

details[open] p {
  animation: animateUp 0.5s linear forwards;
}

@keyframes animateUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
