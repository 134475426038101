<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-justify-start">
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-text-center lg:tw-gap-4"
        v-html="vars.titleHtml"
      ></div>
    </div>
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-2"
      :style="`color: var(--c-text_black)`"
    >
      <div
        v-for="(item, index) in values"
        :key="`ourvalues-item-${index}`"
        class="n-card our-values-item tw-relative tw-bg-white tw-p-12"
      >
        <div class="lg:tw-h-[70px]">
          <h4 :title="item.title" class="lg:tw-line-clamp-2">
            {{ item.title }}
          </h4>
        </div>
        <div class="tw-h-full">
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurValues',

  mixins: [ComponentMixin],

  computed: {
    values() {
      return this.groupedVariables.values;
    },
  },
});
</script>
<style scoped>
.our-values-item::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 5px;
  height: 34px;
  background-color: var(--c-primary);
}
</style>
