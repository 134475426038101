<template>
  <section
    class="n-section-primary"
    :class="{
      '!tw-pb-0': variant === variants.V3Blogs,
    }"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-w-full tw-flex-col"
      :class="
        variants.V2Faqs === variant
          ? 'tw-items-start tw-justify-start'
          : 'tw-items-center tw-justify-center'
      "
    >
      <div v-if="vars.mainTitleHtml" v-html="vars.mainTitleHtml"></div>

      <div
        class="tw-flex tw-w-full tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-space-x-4 md:tw-space-y-0"
        :class="variant === variants.V3Blogs ? 'tw-mb-2' : 'tw-mb-24'"
      >
        <router-link
          v-for="(tab, index) in tabs"
          :key="`knowledge-hub-tab-${index}`"
          :class="activeTab === tab.name ? 'n-primary' : 'n-tertiary'"
          :to="`/knowledge-hub/${tab.name}`"
          style="
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
          "
          class="tw-px-4 tw-py-6 md:tw-w-1/4"
        >
          {{ tab.title }}
        </router-link>
      </div>

      <div
        class="tw-w-full tw-text-center"
        :class="{
          '!tw-my-10': variant === variants.V3Blogs,
        }"
        v-if="vars.subTitleHtml"
        v-html="vars.subTitleHtml"
      ></div>

      <div v-if="variant === variants.V2Faqs" class="tw-my-5 tw-w-full">
        <search-box
          v-if="vars.isSearchActiveBool"
          ref="searchBox"
          v-model="faqSearchKeyword"
          :search-result-list="searchResultList"
          :search-method="onSearchFaqs"
          placeholder="Search"
        />
      </div>
      <div
        v-if="variant === variants.V1Guides"
        class="tw-mb-12 tw-mt-12 tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-y-4 md:tw-flex-row md:tw-gap-x-4 md:tw-gap-y-0"
      >
        <router-link
          v-if="vars.activeTabText !== 'video-faqs'"
          v-for="(tab, index) in subTabs"
          :key="`knowledge-hub-sub-tab-${index}`"
          :class="activeSubTab === tab.name ? 'n-primary' : 'n-tertiary'"
          :to="`${activeTab}` + `${tab.name ? `?type=${tab.name}` : ''}`"
          style="
            text-transform: uppercase;
            border-radius: var(--rounded);
            text-align: center;
            font-weight: bold;
            cursor: pointer;
          "
          class="tw-px-12 tw-py-4"
        >
          {{ tab.title }}
        </router-link>
      </div>
      <div
        v-if="variant === variants.V1Guides"
        class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-x-8 tw-gap-y-8"
      >
        <theme5-components-guide-card
          v-for="(item, index) in guides"
          :key="`guide-${index}`"
          :guide="item"
          class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4 xl:tw-col-span-3"
          :component="component"
        ></theme5-components-guide-card>
      </div>

      <div v-if="variant === variants.V2Faqs" class="tw-w-full">
        <library-accordion v-if="!isFaqResultsLoading" :elements="faqs" />
        <loader v-show="isFaqResultsLoading" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import LibraryAccordion from '~/components/library/content/Accordion.vue';
import SearchBox from '~/components/common/SearchBox.vue';
import { SearchEngine } from '~/core/engines/search.engine';
import { sleep } from '~/helpers';
import Loader from '~/components/common/Loader.vue';
import BlogsPage from '~/components/theme5/blogs/BlogsPage.vue';

export default defineNuxtComponent({
  name: 'Theme5KnowledgeHubPage',

  components: { Loader, SearchBox, LibraryAccordion, BlogsPage },

  mixins: [ComponentMixin],

  data() {
    return {
      tabs: [
        {
          title: 'WRITTEN GUIDES',
          name: 'written-guides',
        },
        {
          title: 'VIDEO GUIDES',
          name: 'video-guides',
        },
        {
          title: 'WRITTEN FAQS',
          name: 'written-faqs',
        },
        {
          title: 'VIDEO FAQS',
          name: 'video-faqs',
        },
        {
          title: 'Blog',
          name: 'blog',
        },
      ],

      variants: {
        V1Guides: 'V1Guides',
        V2Faqs: 'V2Faqs',
        V3Blogs: 'V3Blogs',
      },

      faqSearchKeyword: '',
      searchResultList: [],
      isFaqResultsLoading: false,
    };
  },

  created() {
    const route = useRoute();
    if (route.query.q && this.activeTab === 'faqs') {
      this.faqSearchKeyword = route.query.q;
    }
  },

  computed: {
    activeTab() {
      return this.vars.activeTabText;
    },

    activeSubTab() {
      const route = useRoute();

      if (route.query.type === 'buyers') {
        return 'buyers';
      } else if (route.query.type === 'tenants') {
        return 'tenants';
      } else if (route.query.type === 'sellers') {
        return 'sellers';
      } else if (route.query.type === 'landlords') {
        return 'landlords';
      }
      return '';
    },

    subTitle() {
      return this.vars.subTabTitleText;
    },

    subTabs() {
      return [
        {
          title: 'All',
          name: '',
        },
        {
          title: 'Buyers',
          name: 'buyers',
        },
        {
          title: 'Tenants',
          name: 'tenants',
        },
        {
          title: 'Sellers',
          name: 'sellers',
        },
        {
          title: 'Landlords',
          name: 'landlords',
        },
      ];
    },

    guides() {
      if (this.activeSubTab) {
        const validTypes = ['buyers', 'tenants', 'landlords', 'sellers'];

        return this.groupedVariables.guides.filter((guide) => {
          if (validTypes.includes(this.activeSubTab)) {
            return guide.group_type === this.activeSubTab;
          }

          return false;
        });
      }

      return this.groupedVariables.guides;
    },

    faqs() {
      const route = useRoute();
      if (route.query.q) {
        const keyword = route.query.q as string;

        const searchEngine = new SearchEngine();

        return searchEngine.searchInArray(
          this.groupedVariables.faqs,
          ['title', 'description'],
          keyword,
        );
      }
      return this.groupedVariables.faqs;
    },
  },

  methods: {
    async onSearchFaqs() {
      this.isFaqResultsLoading = true;
      const router = useRouter();

      await sleep(1000);

      await router.replace({
        query: {
          q: this.faqSearchKeyword,
        },
      });
      this.isFaqResultsLoading = false;
    },
  },
});
</script>

<style scoped>
.n-tertiary:hover {
  background-color: white;
  color: unset;
  border-color: var(--c-primary);
}

::v-deep(.accordion-list:not(:last-child)) {
  border-bottom: 1px solid var(--c-border);
}
</style>
