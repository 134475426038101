<template>
  <section
    class="n-section-primary tw-bg-cover tw-bg-center"
    :style="`background-image: url(${vars.backgroundImage})`"
  >
    <div
      class="tw-absolute tw-inset-0 tw-opacity-80"
      :style="`background-color: var(--c-${vars.backgroundColor});`"
    ></div>
    <h3 class="n-text-shadow tw-z-10 tw-text-center tw-text-white">
      {{ vars.titleText }}
    </h3>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TextBanner',

  mixins: [ComponentMixin],
});
</script>
