<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-10 xl:tw-gap-24 2xl:tw-flex-row"
      :class="{ 'xl:tw-flex-row-reverse': vars.isReversedBool }"
    >
      <div class="tw-flex tw-flex-col tw-p-0">
        <div
          v-html="vars.titleHtml"
          class="tw-mb-6 tw-flex tw-gap-4 tw-text-white"
        ></div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <p
            v-for="(item, index) in descriptions"
            :key="`ourvaluations-description-${index}`"
            class="tw-max-w-2xl !tw-text-white"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div
        class="tw-flex tw-w-auto tw-flex-col tw-justify-center tw-gap-6 md:tw-flex-row"
      >
        <div
          v-for="(valuation, index) in valuations"
          :key="`ourvaluations-valuation-${index}`"
          class="n-card tw-flex tw-min-w-[300px] tw-max-w-xl tw-flex-[1_1_40%] tw-flex-col tw-items-center tw-justify-start tw-border-b-8 tw-border-[var(--c-primary)] tw-bg-white tw-text-black xl:tw-h-auto xl:tw-w-full xl:tw-flex-[1_1_20%]"
        >
          <LibraryImage :src="valuation.photo" :h-ratio="10" :w-ratio="16" />
          <div
            class="tw-mt-2 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-stretch tw-gap-5 tw-p-5 sm:tw-p-8 xl:tw-pb-2 xl:tw-pt-6"
          >
            <h4 class="tw-line-clamp-2 xl:tw-h-[60px]">
              {{ valuation.title }}
            </h4>
            <p class="tw-mb-4 tw-line-clamp-[6]">
              {{ valuation.description }}
            </p>
            <nuxt-link
              :external="true"
              class="tw-mt-auto tw-cursor-pointer !tw-whitespace-normal tw-text-center"
              :to="valuation.button_link"
              :class="`n-${valuation.button_type} tw-mb-4 tw-mt-auto`"
              >{{ valuation.button_label }}</nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurValuations',

  mixins: [ComponentMixin],

  computed: {
    valuations() {
      return this.groupedVariables.valuations;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
